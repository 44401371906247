import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import SEO from "../components/SEO"
import Loading from "../components/Loading"

import { GET_GENERAL_DONATION_BY_SESSION_ID } from "../queries/findGeneralDonationBySessionId"
import { useQuery } from "@apollo/client"
import { loginUser } from "../utils/auth"
import { Magic } from "magic-sdk"

import styles from "../styles/modules/static-pages.module.scss"

const useSessionId = () => {
  const queryParams = new URLSearchParams(useLocation().search)
  return queryParams.get("id")
}

const GeneralFundSuccess = () => {
  const sessionId = useSessionId()
  const [isRecurring, setIsRecurring] = useState(false)

  const { loading, data, error } = useQuery(
    GET_GENERAL_DONATION_BY_SESSION_ID,
    {
      variables: {
        session_id: sessionId,
      },
    }
  )

  let magic
  useEffect(() => {
    if (window !== undefined) {
      magic = new Magic(process.env.GATSBY_MAGIC_API_PUBKEY)
    }
    if (data && data.general_fund_donations) {
      const specifics =
        data.general_fund_donations && data.general_fund_donations.pop()
      if (specifics && specifics.email && magic) {
        loginUser(magic, specifics.email, null, false)
        setIsRecurring(true)
      }
    }
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return (
      <>
        <p className={`body-copy ${styles.paragraph}`}>
          Oops, looks like something went wrong :(
        </p>
        <p className={`tiny-body-copy ${styles.paragraph}`}>
          We couldn't find information on your donation. Please check your email
          for a receipt and confirmation.
        </p>
      </>
    )
  }

  return (
    <div className="wrapper wrapper--centered">
      <section>
        <SEO title={"Thank you for sharing"} />
        <h2 className="x-large-body-copy">Thank you for sharing.</h2>
        {isRecurring && (
          <>
            <br />
            <p className="body-copy">
              Please check your email to verify your account.
            </p>
            <br />
            <p className="body-copy">
              You can change your recurring donation settings at any time by
              visiting{" "}
              <a href={"https://nbshare.org/manage-subscription"}>
                https://nbshare.org/manage-subscription
              </a>
              .
            </p>
          </>
        )}
      </section>
    </div>
  )
}

export default GeneralFundSuccess
