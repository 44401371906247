import { gql } from "@apollo/client"

export const GET_GENERAL_DONATION_BY_SESSION_ID = gql`
  query getGeneralFundDonationBySession($session_id: String!) {
    general_fund_donations(where: { session_id: { _eq: $session_id } }) {
      name
      email
      amount
    }
  }
`
